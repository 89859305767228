import React from "react"
import { LogoSignUp } from "../Lib"
import Card from "./Card"

const PostCard = ({
  item: post,
  index,
  current,
  id,
  style,
  offset,
  ...props
}) => {
  return (
    <Card
      {...props}
      link={`/blog/${post.slug}`}
      imageFluid={post.imageFluid}
      imageSrc={post.imageSrc}
      imageAlt={post.title}
      imagePosition={post.imagePosition}
      isActive={offset == 0}
      style={style}
    >
      <div className="post_card__title">{post.title}</div>
    </Card>
  )
}

export default PostCard
