import React from "react"
import * as feather from "../../assets/icons/icons"

const Icon = ({ name, className, ...props }) => {
  const iconName = `I_${name}`
  if (!feather[iconName]) return null
  const SelectedIcon = feather[iconName]
  return (
    <i {...props} className={`icon ${className || ""}`}>
      <SelectedIcon />
    </i>
  )
}

export default Icon
