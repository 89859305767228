import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

export const LogoTypePersian = ({ className, ...props }) => (
  <div {...props} className="logo__type-persian">
    بایوکســــت
  </div>
)
export const LogoTypeEnglish = ({ className, ...props }) => (
  <div className="logo__type-english">BioCast</div>
)

export const LogoSignDown = props => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20">
    <path
      d="M7.558,1.978a3.983,3.983,0,0,1,6.884,0L21.451,14a3.988,3.988,0,0,1-3.442,6H3.991A3.988,3.988,0,0,1,.549,14Z"
      transform="translate(22 20) rotate(180)"
    />
  </svg>
)
export const LogoSignUp = props => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20">
    <path
      d="M10.516,5.917a3.983,3.983,0,0,1,6.884,0l7.009,12.025a3.988,3.988,0,0,1-3.442,6H6.949a3.988,3.988,0,0,1-3.442-6Z"
      transform="translate(-2.958 -3.939)"
    />
  </svg>
)

export const LogoSign = ({ className, ...props }) => (
  <div className="logo__sign">
    <div className="logo__down">
      <LogoSignDown />
    </div>
    <div className="logo__up">
      <LogoSignUp />
    </div>
  </div>
)

const Logo = ({ animated, interval = 5000 }) => {
  const [mode, setMode] = useState(false)
  useEffect(() => {
    if (animated) {
      const inter = setInterval(() => {
        setMode(prev => !prev)
      }, interval)

      return () => {
        clearInterval(inter)
      }
    }
  }, [])
  return (
    <div className={`logo ${mode ? "logo-swap" : ""}`}>
      <LogoSign />
      <div className="logo__type">
        <LogoTypeEnglish />
        <LogoTypePersian />
      </div>
    </div>
  )
}

export const LogoFull = () => (
  <div className="logo--full">
    <LogoTypePersian />
    <LogoSign />
    <LogoTypeEnglish />
  </div>
)

Logo.propTypes = {
  animated: PropTypes.bool,
  interval: PropTypes.number,
}

export default Logo
