import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Icon } from "../Lib"

const Button = ({
  children,
  className,
  color = "primary",
  size,
  block,
  icon = null,
  iconName = null,
  Tag = "button",
  ...props
}) => {
  const componentMap = { link: Link, a: "a", button: "button" }
  const SelectedComponent = componentMap[Tag.toLowerCase()]
  return (
    <SelectedComponent
      {...props}
      className={`navigator button button--${color} ${
        size ? `button--${size}` : ""
      } ${block ? "button--block" : ""} ${className || ""}`}
    >
      <div className="button__text">{children}</div>
      {(icon || iconName) && (
        <div className="button__icon">
          {iconName ? <Icon name={iconName} /> : icon}
        </div>
      )}
    </SelectedComponent>
  )
}

Button.propTypes = {
  color: PropTypes.string,
}

export default Button
