import React from "react"
import { SnakePanel } from "./Lib"
import Icon from "./lib/Icon"

const WhereToFollow = ({ afterColor, beforeColor }) => {
  return (
    <SnakePanel afterColor={afterColor} beforeColor={beforeColor}>
      <div className="footer__social">
        <h4 className="footer__social__title">بایوکست را دنبال کنید!</h4>
        <div className="footer__social__logos">
          <a
            className="navigator"
            href="https://telegram.me/biocastpodcast"
            target
          >
            <Icon name="telegram" />
          </a>
          <a
            className="navigator"
            href="https://instagram.com/biocastpodcast/"
            target
          >
            <Icon name="instagram" />
          </a>
          <a
            className="navigator"
            href="https://twitter.com/BioCastPodcast"
            target
          >
            <Icon name="twitter" />
          </a>
        </div>
      </div>
    </SnakePanel>
  )
}

export default WhereToFollow
