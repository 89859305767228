import React from "react"
import { LogoSignUp } from "../Lib"
import Card from "./Card"
import { toPersianNumber } from "../../utils/helpers"

const EpisodeCard = ({
  item: episode,
  index,
  current,
  id,
  standalone,
  style,
  offset,
  ...props
}) => {
  return (
    <Card
      {...props}
      link={`/episode/${episode.slug}`}
      imageFluid={episode.imageFluid}
      imageSrc={episode.imageSrc}
      imageAlt={episode.title}
      imagePosition={episode.imagePosition}
      isActive={offset === 0}
      standalone={standalone}
      style={style}
    >
      <div className="episode_card__box">
        <div className="episode_card__info">
          <div className="episode_card__number">
            {toPersianNumber(episode.number)}
            <span>#</span>
          </div>
          <div className="episode_card__title">{episode.title}</div>
        </div>
        <LogoSignUp className="episode_card__icon" />
      </div>
    </Card>
  )
}

export default EpisodeCard
