import React from "react"
import { Header, LogoSignUp, ListenToButton, Tag, Icon } from "../Lib"
import { toPersianNumber } from "../../utils/helpers"
const HeroCover = ({ lastEpisode }) => {
  return (
    <>
      <LogoSignUp className="back_pattern" />
      <section className="hero_cover">
        <div className="last_episode">
          <div className="last_episode__info">
            <div>
              <div className="last_episode__number">
                {toPersianNumber(lastEpisode.number)}
              </div>
              <Tag className="last_episode__tag">جدیدترین اپیزود</Tag>
              <h3 className="last_episode__title">{lastEpisode.title}</h3>
              <p className="last_episode__description">
                {lastEpisode.description}
              </p>
            </div>
            <div className="last_episode__cta">
              <ListenToButton
                to={`/episode/${lastEpisode.slug}`}
                Tag="Link"
                size="large"
              />
            </div>
          </div>
          <div className="last_episode__image">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 687.192 650.407"
            >
              <defs>
                <clipPath id="a">
                  <path
                    d="M196.536,51.445c39.985-68.594,139.026-68.594,179.011,0L557.812,364.12c40.3,69.128-9.532,155.942-89.505,155.942H103.777c-79.974,0-129.8-86.814-89.505-155.942Z"
                    transform="matrix(-0.966, -0.259, 0.259, -0.966, 660.036, 801.234)"
                    fill="#63a1a3"
                  />
                </clipPath>
                <pattern
                  id="b"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                >
                  <image
                    preserveAspectRatio="xMidYMid slice"
                    width="100%"
                    href={lastEpisode.imageSquare}
                  />
                </pattern>
              </defs>
              <g transform="translate(-107.446 -150.827)" clipPath="url(#a)">
                <rect
                  width="572.708"
                  height="809.929"
                  transform="translate(204.464 70.934)"
                  fill="url(#b)"
                />
              </g>
            </svg>
          </div>
        </div>
      </section>
    </>
  )
}

export default HeroCover
