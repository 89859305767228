import React from "react"

const FancyButton = React.forwardRef((props, ref) => (
  <button ref={ref} className="FancyButton">
    {props.children}
  </button>
))

const Section = React.forwardRef(
  (
    {
      children,
      className,
      curved,
      backgroundColor,
      padding = {
        right: "var(--section-horizontal-padding)",
        left: "var(--section-horizontal-padding)",
        top: "var(--section-vertical-padding)",
        bottom: "var(--section-vertical-padding)",
      },
      ...props
    },
    ref
  ) => {
    return (
      <section
        {...props}
        className={`section ${className || ""}`}
        ref={ref}
        style={{
          ...(curved && { borderRadius: "2rem" }),
          ...(backgroundColor && { backgroundColor }),
          ...(padding.top && { paddingTop: padding.top }),
          ...(padding.right && { paddingRight: padding.right }),
          ...(padding.bottom && { paddingBottom: padding.bottom }),
          ...(padding.left && { paddingLeft: padding.left }),
        }}
      >
        {children}
      </section>
    )
  }
)

export default Section
