import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const Card = ({
  imageSrc,
  imageFluid,
  imageAlt,
  imagePosition = "center",
  children,
  className,
  isActive,
  standalone,
  overlayColor = "--secondary",
  tag = "div",
  tagComponent,
  link,
  ...props
}) => {
  const calculatedClassName = `navigator card ${`card${overlayColor}`} ${
    standalone
      ? "card--standalone"
      : isActive
      ? "card--active"
      : "card--inactive"
  } ${className || ""}`

  const content = (
    <>
      <div className="card__image">
        {imageFluid ? (
          <Img
            fluid={imageFluid}
            alt={imageAlt}
            imgStyle={{ objectPosition: imagePosition }}
          />
        ) : (
          <img
            src={imageSrc}
            alt={imageAlt}
            style={{ objectPosition: imagePosition }}
          />
        )}
      </div>
      <div
        className="card__content"
        style={{ height: tagComponent ? "100%" : "auto" }}
      >
        {tagComponent}
        <div className="card__content__wrapper">{children}</div>
      </div>
    </>
  )
  if (tag == "Link") {
    return (
      <Link {...props} className={calculatedClassName} to={link}>
        {content}
      </Link>
    )
  } else {
    return (
      <div {...props} className={calculatedClassName}>
        {content}
      </div>
    )
  }
}

export default Card
