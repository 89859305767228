import React from "react"
import WhereToFollow from "./WhereToFollow"

const Footer = ({ afterColor = "primary", beforeColor = "lightgray" }) => {
  return (
    <footer className="footer">
      <WhereToFollow {...{ afterColor, beforeColor }} />
      <div className="footer__content">
        <p>تمام حقوق مولف برای بایوکست محفوظ است.</p>
        <p>
          طراحی و توسعه توسط
          <a className="link" href="http://mkal.ir">
            {" "}
            مهدی کلهر
          </a>
        </p>
      </div>
    </footer>
  )
}

export default Footer
