import React, { useEffect, useState } from "react"
import Icon from "./Icon"
import Button from "./Button"

const ActionBar = () => {
  const [active, setActive] = useState(false)
  useEffect(() => {
    function scrollHandler(e) {
      if (window.scrollY >= window.innerHeight + 500) {
        setActive(true)
      } else {
        setActive(false)
      }
    }
    window.addEventListener("scroll", scrollHandler)
    return () => {
      window.removeEventListener("scroll", scrollHandler)
    }
  }, [])

  const clicked = e => {
    window.scrollTo(0, 0)
  }
  return (
    <div className="action_bar">
      <Button
        onClick={clicked}
        className={`back_to_top ${active && " back_to_top--active"}`}
      >
        <Icon name="arrow_up" />
      </Button>
    </div>
  )
}

const ActionBarForArticle = ({ showMore, setShowLess }) => {
  const [active, setActive] = useState(false)
  const [showButton, setShowButton] = useState(false)
  useEffect(() => {
    const sectionScrollHeight = document.querySelector(".episode_page__read")
      .scrollHeight
    function scrollHandler(e) {
      const sectionClientHeight = document.querySelector(".episode_page__read")
        .clientHeight
      if (window.scrollY >= sectionScrollHeight) {
        setActive(true)
      } else {
        setShowButton(false)
        setActive(false)
      }

      if (
        window.scrollY >= sectionScrollHeight &&
        window.scrollY <= sectionClientHeight + sectionScrollHeight
      ) {
        setShowButton(true)
      } else {
        setShowButton(false)
      }
    }
    window.addEventListener("scroll", scrollHandler)
    return () => {
      window.removeEventListener("scroll", scrollHandler)
    }
  }, [])

  const clicked = e => {
    window.scrollTo(0, 0)
  }
  return (
    <div className={`action_bar`}>
      <Button
        onClick={clicked}
        className={`back_to_top ${active && " back_to_top--active"}`}
      >
        <Icon name="arrow_up" />
      </Button>
      <div
        className={`action_bar__actions ${
          showButton && showMore && "action_bar__actions--active"
        }`}
      >
        <Button className={`action_bar__show_less`} onClick={setShowLess}>
          نمایش کمتر
        </Button>
        <Button
          onClick={() => {
            const article = document.querySelector(".article__content")
            const fontSize = Number(
              getComputedStyle(article)
                .getPropertyValue("--text-size")
                .replace("rem", "")
            )
            article.style.setProperty(
              "--text-size",
              Math.min(fontSize + 0.125, 2) + "rem"
            )
          }}
        >
          +{" "}
        </Button>
        <Button
          onClick={() => {
            const article = document.querySelector(".article__content")
            const fontSize = Number(
              getComputedStyle(article)
                .getPropertyValue("--text-size")
                .replace("rem", "")
            )
            article.style.setProperty(
              "--text-size",
              Math.max(fontSize - 0.125, 0.75) + "rem"
            )
          }}
        >
          -
        </Button>
      </div>
    </div>
  )
}

export { ActionBarForArticle }
export default ActionBar
