import React from "react"

const SnakePanel = ({
  children,
  afterColor = "white",
  beforeColor = "lightgray",
  color = "secondary",
  className,
  ...props
}) => {
  return (
    <section
      {...props}
      className={`snake-panel b-${beforeColor} a-${afterColor} ${
        className || ""
      }`}
    >
      <div className={`snake-panel__box snake-panel__box--${color}`}>
        {children}
      </div>
    </section>
  )
}

export default SnakePanel
