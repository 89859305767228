export const toPersianNumber = number => {
  const mapper = {
    0: "۰",
    1: "۱",
    2: "۲",
    3: "۳",
    4: "۴",
    5: "۵",
    6: "۶",
    7: "۷",
    8: "۸",
    9: "۹",
  }
  return String(number)
    .split("")
    .map(n => mapper[n])
    .join("")
}

export const sortBasedCreationDate = (a, b) => {
  if (Date(a.created_at) > Date(b.created_at)) return 1
  else return -1
}

export const toJalaliDate = date => {
  return new Intl.DateTimeFormat("fa-IR", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(date)
}

export function slugify(string) {
  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text
}

export const transformEpisodesData = episodes => {
  return Array.from(episodes).map(({ node: episode }) => {
    return {
      title: episode?.title,
      id: episode?.id,
      number: episode?.episodeNumber,
      strapiId: episode?.strapiId,
      imageSrc: episode?.mainImage?.childImageSharp?.fluid?.src,
      imageFluid: episode?.mainImage?.childImageSharp?.fluid,
      imageSquare: episode?.squareImage?.childImageSharp?.fluid?.src,
      description: episode?.description,
      text: episode?.text,
      slug: episode?.fields?.slug,
    }
  })
}
export const transformPostsData = posts => {
  return Array.from(posts).map(({ node: post }) => {
    return {
      title: post?.title,
      id: post?.id,
      imageSrc: post?.mainImage?.childImageSharp?.fluid?.src,
      imageFluid: post?.mainImage?.childImageSharp?.fluid,
      description: post?.content,
      imagePosition: post?.imagePosition,
      content: post?.content,
      slug: post?.fields?.slug,
    }
  })
}

export const estimateReadingTime = text => {
  const wordsCount = text.split(" ").length
  return Math.ceil(wordsCount / 180)
}

export const createFullName = (firstName, lastName) => {
  return `${firstName} ${lastName}`
}

export const concatTags = tags => {
  return tags.join(" و ")
}

export const concatPeople = people => {
  return concatTags(people.map(({ node: person }) => person.shownName))
}

export const toTitleCase = string => {
  var smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i
  var alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/
  var wordSeparators = /([ :–—-])/

  return string
    .split(wordSeparators)
    .map(function (current, index, array) {
      if (
        /* Check for small words */
        current.search(smallWords) > -1 &&
        /* Skip first and last word */
        index !== 0 &&
        index !== array.length - 1 &&
        /* Ignore title end and subtitle start */
        array[index - 3] !== ":" &&
        array[index + 1] !== ":" &&
        /* Ignore small words that start a hyphenated phrase */
        (array[index + 1] !== "-" ||
          (array[index - 1] === "-" && array[index + 1] === "-"))
      ) {
        return current.toLowerCase()
      }

      /* Ignore intentional capitalization */
      if (current.substr(1).search(/[A-Z]|\../) > -1) {
        return current
      }

      /* Ignore URLs */
      if (array[index + 1] === ":" && array[index + 2] !== "") {
        return current
      }

      /* Capitalize the first letter */
      return current.replace(alphanumericPattern, function (match) {
        return match.toUpperCase()
      })
    })
    .join("")
}
