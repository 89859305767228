import React from "react"

const Container = ({ children, className, maxWidth, ...props }) => {
  return (
    <div
      {...props}
      className={`container ${className || ""}`}
      style={{ maxWidth }}
    >
      {children}
    </div>
  )
}

export default Container
