import React from "react"
import { Link } from "gatsby"

import Button from "./Button"

const ReadButton = props => {
  return (
    <Button {...props} iconName="book_open">
      بخوانید
    </Button>
  )
}

export default ReadButton
