import React, { useRef } from "react"
import Logo from "./Logo"
import Icon from "./Icon"
import { Link } from "gatsby"

const Header = ({ className, pushed, ...props }) => {
  const nav = useRef()
  const exitNav = e => {
    e.preventDefault()
    nav.current.classList.remove("nav--active")
  }
  const showNav = e => {
    e.preventDefault()
    nav.current.classList.add("nav--active")
  }
  return (
    <header
      {...props}
      className={`header${pushed ? " header--pushed" : ""} ${className || ""}`}
    >
      <Link to="/" className="navigator">
        <Logo animated interval={10000} />
      </Link>
      <nav className="nav" ref={nav}>
        <Icon className="nav__exit" name="arrow_left" onClick={exitNav} />
        <Link className="nav__link" to="/episodes">
          اپیزود‌ها
        </Link>
        <Link className="nav__link" to="/blog">
          بلاگ
        </Link>
        <Link className="nav__link" to="/sponsorship">
          پشتیبانی و حمایت مالی
        </Link>
        <Link className="nav__link" to="/about">
          درباره ما
        </Link>
      </nav>
      <Icon className="nav__show" name="menu" onClick={showNav} />
    </header>
  )
}

export default Header
