import React from "react"
import { Link } from "gatsby"
import Button from "./Button"
import { LogoSignUp } from "./Logo"

const ListenToButton = props => {
  return (
    <Button
      {...props}
      icon={<LogoSignUp style={{ transform: "rotate(90deg)" }} />}
    >
      بشنوید
    </Button>
  )
}

export default ListenToButton
